document.addEventListener('DOMContentLoaded', () => {

  // ===============================================
  // 1) CREATE & CACHE THREE DISPLACEMENT TEXTURES
  // ===============================================
  function createDisplacementTexture(version) {
    const size = 256;
    const offCanvas = document.createElement('canvas');
    offCanvas.width = size;
    offCanvas.height = size;
    const ctx = offCanvas.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 0, size);

    if (version === 1) {
      gradient.addColorStop(0.0, '#000000');
      gradient.addColorStop(0.33, '#000000');
      gradient.addColorStop(0.66, '#FFFFFF');
      gradient.addColorStop(1.0, '#000000');
    } else {
      gradient.addColorStop(0.0, '#000000');
      gradient.addColorStop(0.33, '#FFFFFF');
      gradient.addColorStop(0.66, '#000000');
      gradient.addColorStop(1.0, '#000000');
    }

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, size, size);
    return offCanvas;
  }

  // Build a cache of THREE distinct displacement maps
  const displacementCache = [
    createDisplacementTexture(1),
    createDisplacementTexture(2),
    createDisplacementTexture(3),
  ];

  // ===============================================
  // 2) PARAMETER SETS (No vertical “distortion”)
  // ===============================================
  const parameterSets = [
    { maxContraction: 0.1 },
    { maxContraction: 0.2 },
    { maxContraction: 0.3 }
  ];

  // ===============================================
  // 3) SELECT MULTIPLE IMAGES WITH CLASS .distort-auto
  // ===============================================
  const autoImages = document.querySelectorAll('img.distort-auto');
  if (!autoImages.length) return;

  autoImages.forEach((img) => {
    // Wait for each image to load so it has a valid size
    if (img.complete && img.naturalWidth > 0) {
      initAutoWave(img);
    } else {
      img.addEventListener('load', () => {
        initAutoWave(img);
      }, { once: true });
    }
  });

  // The main function that sets up everything for each image
  function initAutoWave(img) {
    // 1) Measure bounding rect
    let rect = img.getBoundingClientRect();
    let width = rect.width;
    let height = rect.height;

    // 2) If boundingRect is zero, fallback to natural size
    if (!width || !height) {
      width = img.naturalWidth;
      height = img.naturalHeight;
    }

    // 3) If STILL zero, skip
    if (!width || !height) {
      console.warn('Image size is 0. Distortion aborted.');
      return;
    }

    // 4) Random displacement
    const randomIndex = Math.floor(Math.random() * displacementCache.length);
    const displacementCanvas = displacementCache[randomIndex];

    // 5) Random parameter set
    const params = parameterSets[Math.floor(Math.random() * parameterSets.length)];
    const { maxContraction } = params; // no actual distortion if 0.0

    // 6) Prepare container
    const container = img.parentElement;
    if (getComputedStyle(container).position === 'static') {
      container.style.position = 'relative';
    }

    // 7) Create a matching canvas
    const dpr = window.devicePixelRatio || 1;
    const canvas = document.createElement('canvas');
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    canvas.style.width = '100%';
    canvas.style.height = 'auto';
    canvas.style.position = 'absolute';
    canvas.style.top = `${img.offsetTop}px`;
    canvas.style.left = `${img.offsetLeft}px`;
    canvas.style.zIndex = '1';
    container.appendChild(canvas);

    // Hide original
    img.style.display = 'none';

    // 8) WebGL init
    const gl = canvas.getContext('webgl', { alpha: true });
    if (!gl) {
      console.error('WebGL not supported.');
      return;
    }

    // Disable unused features for 2D
    gl.disable(gl.DEPTH_TEST);
    gl.disable(gl.CULL_FACE);
    gl.disable(gl.BLEND);

    gl.clearColor(0, 0, 0, 0);

    // Vertex and fragment shaders
    const vertexShaderSource = `
      attribute vec2 aPosition;
      attribute vec2 aTexCoord;
      uniform float uContentHeight;
      varying vec2 vUv;
      void main() {
        // Flip Y so it’s upright, then scale by uContentHeight
        vec2 pos = aPosition;
        pos.y = -pos.y * uContentHeight;
        gl_Position = vec4(pos, 0.0, 1.0);
        vUv = aTexCoord;
      }
    `;
    const fragmentShaderSource = `
      precision mediump float;
      uniform sampler2D uImage;
      uniform sampler2D uDisplacement;
      uniform float uTime;
      uniform float uDistortionStrength;
      varying vec2 vUv;

      void main() {
        float displacement = texture2D(uDisplacement, vec2(vUv.x, mod(vUv.y + uTime, 1.0))).r;
        float offset = (1.0 - 4.0 * displacement) * uDistortionStrength;
        float edgeFactor = smoothstep(0.0, 0.15, vUv.y)
                         * (1.0 - smoothstep(0.85, 1.0, vUv.y));
        offset *= edgeFactor;
        vec2 displacedUv = vec2(vUv.x, clamp(vUv.y + offset, 0.0, 1.0));

        // Sample the image
        vec4 color = texture2D(uImage, displacedUv);

        // ALPHA THRESHOLD / CUT-OUT
        if (color.a < 0.95) {
          discard;
        }

        gl_FragColor = color;
      }
    `;

    function compileShader(source, type) {
      const shader = gl.createShader(type);
      gl.shaderSource(shader, source);
      gl.compileShader(shader);
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('Shader compile error:', gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
      }
      return shader;
    }
    const vertexShader = compileShader(vertexShaderSource, gl.VERTEX_SHADER);
    const fragmentShader = compileShader(fragmentShaderSource, gl.FRAGMENT_SHADER);

    const program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.error('Program link error:', gl.getProgramInfoLog(program));
      return;
    }
    gl.useProgram(program);

    // Uniform: content height
    const uContentHeightLocation = gl.getUniformLocation(program, 'uContentHeight');
    gl.uniform1f(uContentHeightLocation, 1.0);

    // Quad geometry
    const vertices = new Float32Array([
      //   X    Y   U   V
      -1, -1,  0,  0,
       1, -1,  1,  0,
      -1,  1,  0,  1,
      -1,  1,  0,  1,
       1, -1,  1,  0,
       1,  1,  1,  1
    ]);
    const vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

    const aPosition = gl.getAttribLocation(program, 'aPosition');
    const aTexCoord = gl.getAttribLocation(program, 'aTexCoord');
    gl.enableVertexAttribArray(aPosition);
    gl.enableVertexAttribArray(aTexCoord);

    const stride = 4 * Float32Array.BYTES_PER_ELEMENT;
    gl.vertexAttribPointer(aPosition, 2, gl.FLOAT, false, stride, 0);
    gl.vertexAttribPointer(aTexCoord, 2, gl.FLOAT, false, stride, 2 * Float32Array.BYTES_PER_ELEMENT);

    // Create textures
    const imageTexture = gl.createTexture();
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, imageTexture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    if (img.complete) {
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img);
    } else {
      img.onload = () => {
        gl.bindTexture(gl.TEXTURE_2D, imageTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img);
      };
    }

    // Displacement
    const displacementTexture = gl.createTexture();
    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, displacementTexture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      displacementCanvas
    );

    const uImageLocation = gl.getUniformLocation(program, 'uImage');
    const uDisplacementLocation = gl.getUniformLocation(program, 'uDisplacement');
    const uTimeLocation = gl.getUniformLocation(program, 'uTime');
    const uDistortionStrengthLocation = gl.getUniformLocation(program, 'uDistortionStrength');

    gl.uniform1i(uImageLocation, 0);
    gl.uniform1i(uDisplacementLocation, 1);
    gl.uniform1f(uDistortionStrengthLocation, 0.1);

    gl.viewport(0, 0, canvas.width, canvas.height);

    // IntersectionObserver to skip GPU calls if offscreen
    let isInViewport = false;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        isInViewport = entry.isIntersecting;
      });
    });
    observer.observe(canvas);

    // Autoplay wave
    let time = 0;            
    const waveSpeed = 0.0007;
    const phaseOffset = 0.5; // start mid-cycle

    function render() {
      // If not visible, skip heavy GPU operations
      if (!isInViewport) {
        requestAnimationFrame(render);
        return;
      }

      // Only do wave computations if in viewport
      time += waveSpeed;
      gl.clear(gl.COLOR_BUFFER_BIT);

      // Pass time to the shader
      gl.uniform1f(uTimeLocation, time + phaseOffset);

      const wave = Math.abs(Math.sin((time + phaseOffset) * Math.PI));
      const contentHeight = 1.0 - maxContraction * wave;
      gl.uniform1f(uContentHeightLocation, contentHeight);

      // Draw
      gl.drawArrays(gl.TRIANGLES, 0, 6);

      requestAnimationFrame(render);
    }
    requestAnimationFrame(render);
  }

});
