document.addEventListener('DOMContentLoaded', () => {

  // ===============================================
  // 1) CREATE & CACHE THREE DISPLACEMENT TEXTURES
  // ===============================================
  function createDisplacementTexture(version) {
    const size = 256;
    const offCanvas = document.createElement('canvas');
    offCanvas.width = size;
    offCanvas.height = size;
    const ctx = offCanvas.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 0, size);

    if (version === 1) {
      gradient.addColorStop(0.0, '#000000');
      gradient.addColorStop(0.33, '#000000');
      gradient.addColorStop(0.66, '#FFFFFF');
      gradient.addColorStop(1.0, '#000000');
    } else {
      gradient.addColorStop(0.0, '#000000');
      gradient.addColorStop(0.33, '#FFFFFF');
      gradient.addColorStop(0.66, '#000000');
      gradient.addColorStop(1.0, '#000000');
    }

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, size, size);
    return offCanvas;
  }

  // Build a cache of THREE distinct displacement maps
  const displacementCache = [
    createDisplacementTexture(1),
    createDisplacementTexture(2),
    createDisplacementTexture(3),
  ];

  // ===============================================
  // 2) PARAMETER SETS
  // ===============================================
  const parameterSets = [
    { maxContraction: 0.1 },
    { maxContraction: 0.2 },
    { maxContraction: 0.3 }
  ];

  // ===============================================
  // 3) SELECT & INITIALIZE EACH IMAGE
  // ===============================================
  const images = document.querySelectorAll('img.distort-on-scroll');
  if (!images.length) return;

  // Avoid same displacement version on consecutive images
  let lastVersionIndex = -1;
  function pickRandomVersionIndexWithoutRepeat() {
    let idx = Math.floor(Math.random() * displacementCache.length);
    if (displacementCache.length > 1) {
      while (idx === lastVersionIndex) {
        idx = Math.floor(Math.random() * displacementCache.length);
      }
    }
    lastVersionIndex = idx;
    return idx;
  }

  images.forEach((img) => {
    // 1) Random displacement
    const randomVersionIndex = pickRandomVersionIndexWithoutRepeat();
    const displacementCanvas = displacementCache[randomVersionIndex];

    // 2) Random parameter set
    const params = parameterSets[Math.floor(Math.random() * parameterSets.length)];
    const { maxContraction } = params;

    // Sizing & container
    const rect = img.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;
    const dpr = window.devicePixelRatio || 1;

    const container = img.parentElement;
    if (getComputedStyle(container).position === 'static') {
      container.style.position = 'relative';
    }

    // Create our canvas
    const canvas = document.createElement('canvas');
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    canvas.style.width = '100%';
    canvas.style.height = 'auto';
    canvas.style.position = 'absolute';
    canvas.style.top = `${img.offsetTop}px`;
    canvas.style.left = `${img.offsetLeft}px`;
    canvas.style.zIndex = '1';
    container.appendChild(canvas);
    img.style.display = 'none';

    // WebGL context
    const gl = canvas.getContext('webgl');
    if (!gl) {
      console.error('WebGL not supported.');
      return;
    }

    // Disable unused states for performance
    gl.disable(gl.DEPTH_TEST);
    gl.disable(gl.CULL_FACE);
    gl.disable(gl.BLEND);

    gl.clearColor(0, 0, 0, 0);

    // Vertex & fragment shaders
    const vertexShaderSource = `
      attribute vec2 aPosition;
      attribute vec2 aTexCoord;
      uniform float uContentHeight;
      varying vec2 vUv;
      void main() {
          // Flip Y coordinate so it's not upside down
          // then scale by uContentHeight
          vec2 pos = aPosition;
          pos.y = -pos.y * uContentHeight;
          gl_Position = vec4(pos, 0.0, 1.0);
          vUv = aTexCoord;
      }
    `;
    const fragmentShaderSource = `
      precision mediump float;
      uniform sampler2D uImage;
      uniform sampler2D uDisplacement;
      uniform float uTime;
      uniform float uDistortionStrength;
      varying vec2 vUv;

      void main() {
          float displacement = texture2D(uDisplacement, vec2(vUv.x, mod(vUv.y + uTime, 1.0))).r;
          float offset = (1.0 - 4.0 * displacement) * uDistortionStrength;
          float edgeFactor = smoothstep(0.0, 0.15, vUv.y)
                           * (1.0 - smoothstep(0.85, 1.0, vUv.y));
          offset *= edgeFactor;

          // Distort final texcoord
          vec2 displacedUv = vec2(vUv.x, clamp(vUv.y + offset, 0.0, 1.0));

          // Sample main image
          vec4 color = texture2D(uImage, displacedUv);

          // Discard fragments with alpha below 0.95 for sharp edges
          if (color.a < 0.95) {
            discard;
          }

          gl_FragColor = color;
      }
    `;

    // Compile Shaders
    function compileShader(src, type) {
      const shader = gl.createShader(type);
      gl.shaderSource(shader, src);
      gl.compileShader(shader);
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('Shader compile error:', gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
      }
      return shader;
    }
    const vertexShader = compileShader(vertexShaderSource, gl.VERTEX_SHADER);
    const fragmentShader = compileShader(fragmentShaderSource, gl.FRAGMENT_SHADER);

    // Link Program
    const program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.error('Program linking error:', gl.getProgramInfoLog(program));
      return;
    }
    gl.useProgram(program);

    // Uniform: content height
    const uContentHeightLocation = gl.getUniformLocation(program, 'uContentHeight');
    gl.uniform1f(uContentHeightLocation, 1.0);

    // Fullscreen Quad
    const vertices = new Float32Array([
      -1, -1,  0, 0,
       1, -1,  1, 0,
      -1,  1,  0, 1,
      -1,  1,  0, 1,
       1, -1,  1, 0,
       1,  1,  1, 1
    ]);
    const vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

    const aPosition = gl.getAttribLocation(program, 'aPosition');
    const aTexCoord = gl.getAttribLocation(program, 'aTexCoord');
    gl.enableVertexAttribArray(aPosition);
    gl.enableVertexAttribArray(aTexCoord);
    gl.vertexAttribPointer(aPosition, 2, gl.FLOAT, false, 4 * Float32Array.BYTES_PER_ELEMENT, 0);
    gl.vertexAttribPointer(aTexCoord, 2, gl.FLOAT, false, 4 * Float32Array.BYTES_PER_ELEMENT, 2 * Float32Array.BYTES_PER_ELEMENT);

    // Textures
    const imageTexture = gl.createTexture();
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, imageTexture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    if (img.complete) {
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img);
    } else {
      img.onload = () => {
        gl.bindTexture(gl.TEXTURE_2D, imageTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img);
      };
    }

    // Displacement
    const displacementTexture = gl.createTexture();
    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, displacementTexture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, displacementCanvas);

    const uImageLocation = gl.getUniformLocation(program, 'uImage');
    const uDisplacementLocation = gl.getUniformLocation(program, 'uDisplacement');
    const uTimeLocation = gl.getUniformLocation(program, 'uTime');
    const uDistortionStrengthLocation = gl.getUniformLocation(program, 'uDistortionStrength');

    gl.uniform1i(uImageLocation, 0);
    gl.uniform1i(uDisplacementLocation, 1);
    gl.uniform1f(uDistortionStrengthLocation, 0.05);

    // Set viewport
    gl.viewport(0, 0, canvas.width, canvas.height);

    // SCROLL LOGIC
    let targetScroll = window.scrollY;
    let currentScroll = window.scrollY;
    window.addEventListener('scroll', () => {
      targetScroll = window.scrollY;
    });

    let isInViewport = false;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        isInViewport = entry.isIntersecting;
      });
    });
    observer.observe(canvas);

    // RENDER LOOP
    function render() {
      // Smoothly track the user's scroll
      currentScroll += (targetScroll - currentScroll) * 0.1;

      if (isInViewport) {
        gl.clear(gl.COLOR_BUFFER_BIT);

        // Send time-based uniform
        gl.uniform1f(uTimeLocation, currentScroll * 0.001);

        // Minimal vertical contraction
        const wave = Math.abs(Math.sin(currentScroll * 0.001 * Math.PI));
        const contentHeight = 1.0 - maxContraction * wave;
        gl.uniform1f(uContentHeightLocation, contentHeight);

        // Draw
        gl.drawArrays(gl.TRIANGLES, 0, 6);
      }

      requestAnimationFrame(render);
    }
    requestAnimationFrame(render);
  });
});
