// main.js
import "@/styles/index.scss";
import "./utils/scroll";
import "./modules/Menu";
import "./modules/DVDPlayer";
import "./modules/Collage";
import "./modules/ProgramAccordion";
// import { gsap } from "gsap";
// import Preloader from "./components/Preloader";

import "./modules/waveScroll";
import "./modules/waveAuto";

class App {
  constructor() {
    if (import.meta.hot) {
      import.meta.hot.accept(() => {
        console.log("HMR");
      });
    }

    this._setHeaderHeight();
    // this._createPreloader();
    this._addEventListeners();
    this._render();
  }

  _setHeaderHeight() {
    const header = document.querySelector("header");
    let headerHeight = header.offsetHeight;
    if (headerHeight === 0) {
      headerHeight = 165;
    }
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
  }

  // _createPreloader() {
  //   const isIndex = document.querySelector('[data-template="index"]');

  //   if (isIndex) {
  //     this.preloader = new Preloader();
  //   } else if (isIndex) {
  //     gsap.to(".hero .artist", {
  //       autoAlpha: 1,
  //       stagger: {
  //         from: "random",
  //         each: 0.05,
  //       },
  //       duration: 0.05,
  //     });
  //   }
  // }

  _onMouseMove(event) {
    if (this.webGL && this.webGL.onMouseMove) {
      this.webGL.onMouseMove(event);
    }
  }

  _onResize() {
    this._setHeaderHeight();
  }

  _addEventListeners() {
    window.addEventListener("resize", this._onResize.bind(this));
    window.addEventListener("mousemove", this._onMouseMove.bind(this));
  }

  _render(time) {
    requestAnimationFrame(this._render.bind(this));
  }
}

new App();

console.log(
  "%c Developed by ANTI - https://anti.as/",
  "background: #000; color: #fff;"
);
